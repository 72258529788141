import React from "react"
import ReactLoading from "react-loading"

import styles from "./index.module.scss"

const Loading = () => {
  return (
    <div className={styles.wrapper}>
      <ReactLoading type="spin" color="#2595d2" height={75} width={75} />
    </div>
  )
}

export default Loading
