import React, { useState } from "react"
import { navigate } from "gatsby"

import { useForm, Controller } from "react-hook-form"
import Select from "react-select"

import styles from "../styles/novo-requerimento.module.scss"

import Layout from "../components/Layout"
import Button from "../components/Button"
import FileUploader from "../components/FileUploader"
import Loading from "../components/Loading"

const NovoRequerimento = () => {
  // Types of "Requerimentos"
  const reqTypes = [
    { value: "Atestado de vida", label: "Atestado de vida" },
    {
      value: "Atestado de residência",
      label: "Atestado de residência",
      messageIsRequired: true,
      message: "(indique, em baixo, a sua morada)",
    },
    {
      value: "Atestado de agregado familiar",
      label: "Atestado de agregado familiar",
      messageIsRequired: true,
      message:
        "(indique, em baixo, o nome e as respetivas datas de nascimento dos membros do agregado familiar)",
    },
    {
      value: "Declaração para transporte escolar",
      label: "Declaração para transporte escolar",
      messageIsRequired: true,
      message: "(indique, em baixo, o estabelecimento de ensino que frequenta)",
    },
    {
      value: "Apoio à natalidade",
      label: "Apoio à natalidade",
      documentsAreRequired: true,
      documents: [
        {
          documentLabel: "Certidão de nascimento *",
          documentName: "certidao-nascimento",
        },
        {
          documentLabel: "Cartão de cidadão do requerente *",
          documentName: "cc-requerente",
        },
        {
          documentLabel: "Comprovativo de IBAN *",
          documentName: "comprovativo-iban",
        },
      ],
    },
    {
      value: "Apoio a pessoa com deficiência",
      label: "Apoio a pessoa com deficiência",
      documentsAreRequired: true,
      documents: [
        {
          documentLabel: "Comprovativo de incapacidade *",
          documentName: "comprovativo-incapacidade",
        },
        {
          documentLabel: "Cartão de cidadão da pessoa com deficiência *",
          documentName: "cc-pessoa-com-deficiencia",
        },
        {
          documentLabel: "Comprovativo de IBAN *",
          documentName: "comprovativo-iban",
        },
      ],
    },
    {
      value: "Apoio à frequência do ensino superior público",
      label: "Apoio à frequência do ensino superior público",
      documentsAreRequired: true,
      documents: [
        {
          documentLabel: "Certificado de matrícula *",
          documentName: "certificado-matricula",
        },
        {
          documentLabel: "Comprovativo de inscrição nas disciplinas *",
          documentName: "comprovativo-inscricao-disciplinas",
        },
        {
          documentLabel: "Comprovativo de aproveitamento escolar *",
          documentName: "comprovativo-aproveitamento-escolar",
        },
        {
          documentLabel: "Recibos de pagamento da propina *",
          documentName: "recibo-pagamento-propina",
        },
        {
          documentLabel: "Cartão de cidadão do requerente *",
          documentName: "cc-requerente",
        },
        {
          documentLabel: "Comprovativo de IBAN *",
          documentName: "comprovativo-iban",
        },
      ],
    },
    {
      value: "Cedência de sepultura perpétua",
      label: "Cedência de sepultura perpétua",
    },
  ]

  // Hooks
  const { register, handleSubmit, control, errors } = useForm()
  const [reqType, setReqType] = useState("")
  const [loading, setLoading] = useState(false)

  // Additional required messages for some specific types
  const isMessageRequired = type => {
    const found = reqTypes.find(o => o.value === type)
    if (found && found.messageIsRequired) {
      return true
    } else {
      return false
    }
  }

  const getRequiredMessage = type => {
    const found = reqTypes.find(o => o.value === type)
    if (found) {
      return found.message
    }
  }

  // Required documents for some specific types
  const isDocumentRequired = type => {
    const found = reqTypes.find(o => o.value === type)
    if (found && found.documentsAreRequired) {
      return true
    } else {
      return false
    }
  }

  const getRequiredDocuments = type => {
    const found = reqTypes.find(o => o.value === type)
    if (found) {
      return found.documents
    }
  }

  // Styles for react-select
  const selectCustomStyles = {
    container: provided => ({
      ...provided,
      margin: "1.366rem 0",
    }),
    control: provided => ({
      ...provided,
      borderRadius: 0,
      border: "none",
      boxShadow: "none",
      backgroundColor: "#F7F7F7",
    }),
    singleValue: provided => ({
      ...provided,
      color: "#00104A",
    }),
    input: provided => ({
      ...provided,
      color: "#00104A",
    }),
    placeholder: provided => ({
      ...provided,
      marginLeft: "1rem",
      marginRight: "1rem",
      color: "#00104A",
      fontStyle: "italic",
    }),
    menu: provided => ({
      ...provided,
      boxShadow: 0,
      borderRadius: 0,
      backgroundColor: "#F7F7F7",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#2595D2" : "#F7F7F7",
      outline: state.isFocused ? "none" : "none",
      padding: "1rem 1.25rem",
      fontStyle: "italic",
      "&:hover": {
        backgroundColor: state.isFocused && !state.isSelected ? "#DEEBFF" : 0,
        cursor: "pointer",
      },
    }),
  }

  // Encoding method
  const encode = data => {
    // Name of fields that contains uploaded files (blobs)
    const files = reqTypes.reduce((acc, type) => {
      if (type.documentsAreRequired) {
        type.documents.forEach(document => {
          !acc.includes(document.documentName) &&
            acc.push(document.documentName)
        })
      }
      return acc
    }, [])

    const formData = new FormData()

    for (const key of Object.keys(data)) {
      if (files.includes(key)) {
        formData.append(key, data[key][0])
      } else {
        formData.append(key, data[key])
      }
    }

    return formData
  }

  // Submit handler
  const onSubmit = data => {
    setLoading(true)
    fetch("/", {
      method: "POST",
      body: encode({ "form-name": "novo-requerimento", ...data }),
    })
      .then(response => {
        if (!response.ok) {
          alert(
            "Alguma coisa correu mal, tente novamente ou contacte a Junta de Freguesia do Touro."
          )
          setLoading(false)
        } else {
          navigate("/requerimento-submetido")
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
        alert(
          "Alguma coisa correu mal, tente novamente ou contacte a Junta de Freguesia do Touro."
        )
      })
  }

  return (
    <Layout>
      {loading && <Loading />}
      {!loading && (
        <div className={styles.wrapper}>
          <div className={styles.form}>
            <h2>Requerimento</h2>
            <form
              name="novo-requerimento"
              method="post"
              data-netlify="true"
              netlify-honeypot="bot-field"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.type}>
                <label htmlFor="requerimento">
                  Tipo de Requerimento *
                  <br />
                  <div className={styles.typeSelectWrapper}>
                    <Controller
                      name="requerimento"
                      control={control}
                      render={({ onChange }) => (
                        <Select
                          placeholder="Selecione"
                          options={reqTypes}
                          styles={selectCustomStyles}
                          isSearchable={false}
                          onChange={e => {
                            setReqType(e.value)
                            onChange(e.value)
                          }}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                      defaultValue=""
                    />
                  </div>
                  {/*Netlify not recognizing react-select html input while building*/}
                  <input type="text" name="requerimento" hidden />
                </label>
                <p className={styles.error}>
                  {errors.requerimento && "Preenchimento obrigatório"}
                </p>
              </div>
              <div className={styles.name}>
                <p>
                  <label htmlFor="name">
                    Nome *
                    <br />
                    <input
                      type="text"
                      name="nome"
                      ref={register({ required: true })}
                    />
                  </label>
                </p>
                <p className={styles.error}>
                  {errors.name && "Preenchimento obrigatório"}
                </p>
              </div>
              <div className={styles.row}>
                <div className={styles.phone}>
                  <p>
                    <label htmlFor="phone">
                      Contacto Telefónico *
                      <br />
                      <input
                        type="tel"
                        name="telefone"
                        ref={register({ required: true })}
                      />
                    </label>
                  </p>
                  <p className={styles.error}>
                    {errors.phone && "Preenchimento obrigatório"}
                  </p>
                </div>
                <div className={styles.cc}>
                  <p>
                    <label htmlFor="cc">
                      Número do Cartão de Cidadão *
                      <br />
                      <input name="cc" ref={register({ required: true })} />
                    </label>
                  </p>
                  <p className={styles.error}>
                    {errors.cc && "Preenchimento obrigatório"}
                  </p>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.nif}>
                  <p>
                    <label htmlFor="nif">
                      Número de Identificação Fiscal *
                      <br />
                      <input name="nif" ref={register({ required: true })} />
                    </label>
                  </p>
                  <p className={styles.error}>
                    {errors.nif && "Preenchimento obrigatório"}
                  </p>
                </div>
                <div className={styles.ss}>
                  <p>
                    <label htmlFor="ss">
                      Número da Segurança Social *
                      <br />
                      <input name="ss" ref={register({ required: true })} />
                    </label>
                  </p>
                  <p className={styles.error}>
                    {errors.ss && "Preenchimento obrigatório"}
                  </p>
                </div>
              </div>
              <div className={styles.request}>
                <p>
                  <label htmlFor="message">
                    {isMessageRequired(reqType) ? "Pedido *" : "Pedido"}
                    {isMessageRequired(reqType) && (
                      <span>{getRequiredMessage(reqType)}</span>
                    )}
                    <textarea
                      name="message"
                      ref={register({
                        required: isMessageRequired(reqType),
                      })}
                      placeholder="Detalhe aqui o seu pedido"
                      rows="8"
                    />
                  </label>
                </p>
                <p className={styles.error}>
                  {errors.message && "Preenchimento obrigatório"}
                </p>
              </div>
              {isDocumentRequired(reqType) &&
                getRequiredDocuments(reqType).map(document => (
                  <div key={document.documentName}>
                    <label htmlFor={document.documentName}>
                      {document.documentLabel}
                      <div className={styles.document}>
                        <div className={styles.fileUploaderWrapper}>
                          <Controller
                            name={document.documentName}
                            control={control}
                            defaultValue=""
                            render={({ onChange, value, name }) => (
                              <FileUploader
                                onChange={e => onChange(e.target.files)}
                                value={value}
                                name={name}
                              />
                            )}
                            rules={{
                              required: true,
                            }}
                          />
                        </div>
                      </div>
                    </label>
                    <p className={styles.error}>
                      {errors[document.documentName] &&
                        "Envio de documento obrigatório"}
                    </p>
                  </div>
                ))}
              {/* Weird "workaround": netlify needs all inputs to have its HTML markup available at deploy time;
            since we're using a .map() above, we are using JS to render the inputs and Netlify won't be able to create those fields
            https://answers.netlify.com/t/html-form-inputs-not-received-creating-form-inputs-with-map/2931  */}
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="certidao-nascimento"
              />
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="cc-requerente"
              />
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="comprovativo-iban"
              />
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="comprovativo-incapacidade"
              />
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="cc-pessoa-com-deficiencia"
              />
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="certificado-matricula"
              />
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="comprovativo-inscricao-disciplinas"
              />
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="comprovativo-aproveitamento-escolar"
              />
              <input
                className={styles.hidden}
                type="file"
                ref={register}
                name="recibo-pagamento-propina"
              />
              {/* End of "workaround" */}
              <div className={styles.submit}>
                <Button type="submit">Submeter Requerimento</Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default NovoRequerimento
