import React from "react"
import { FaUpload } from "react-icons/fa"

import styles from "./index.module.scss"

const FileUploader = ({ onChange, value, name }) => {
  const [file] = value

  return (
    <div className={styles.wrapper}>
      <label>
        <input type="file" onChange={onChange} name={name} />
        Adicionar ficheiro
        <FaUpload />
      </label>
      <div className={styles.filePreview}>{file && file.name}</div>
    </div>
  )
}

export default FileUploader
